@import url("../../constant/variable.css");

.p-card{
    display: grid;
    grid-template-columns: auto 30%;
    padding: 20px;
    margin: 10px auto;
    border: 1px solid var(--primary);
    border-radius: 10px;
}

.p-card .p-option{
    display: grid;
}

.p-card .p-option{
    margin: auto;
}

.p-card h2, p{
    margin: 0;
}