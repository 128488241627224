:root{
    --primary: #3A6B35;
    --base: #EFEFED;
    --wa: #00E676;
    --baseLogo: #B78F37;
    --btnred: #dc3545;
    --btnblue: #3546dc;
}

.f-group{
    width: 250px;
    display: grid;
    grid-gap: 10px;
    margin-bottom: 20px;
}

.f-group label{
   font-weight: 600;
}

.f-group input, select {
    border-radius: 10px;
    height: 35px;
    border: none;
    background: var(--base);
    padding: 0 10px;
}

.f-group input:focus{
    outline: none;
    border: 1px solid var(--primary);
}

.btn{
    background: var(--primary);
    color: white;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--primary);
    font-weight: bold;
    font-size: 16px;
    transition: 0.3s;
}

.btn:enabled{
    cursor: pointer;
}

.btn:hover:enabled{
    background: white;
    color: var(--primary);
    box-shadow: 4px 4px 50px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
}

.btn:disabled{
    cursor: default;
    background: var(--base);
    border: none;
    color: var(--primary);
    transition: 0.3s;
}

.c-width{
    width: 500px;
}

.error {
    font-size: 16px;
    color: #dc3545;
}

.btn-edit, .btn-add, .btn-del{
    cursor: pointer;
    width: 75px;
    height: 35px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
}

.btn-edit:hover, .btn-add:hover, .btn-del:hover{
    background: var(--base);
    color: black;
    transition: 0.3s;
}

.btn-add{
    background: var(--primary);
    color: white;
    transition: 0.3s;
}

.btn-del{
    background: var(--btnred);
    color: white;
    transition: 0.3s;
}

.btn-edit{
    background: var(--btnblue);
    color: white;
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .c-width{
        width: 350px;
    }
}

@media screen and (max-width: 576px) {
    .c-width{
        width: 250px;
    }
}