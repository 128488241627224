@import url("../../constant/variable.css");

.p-menu{
    display: grid;
}

.p-menu form{
    margin: 0 auto;
}

.p-menu h1{
    margin: 20px auto;
}
