@import url("../../constant/variable.css");

nav{
    padding: 20px 100px;
    background: var(--base);
}

nav a{
    text-decoration: none;
    color: black;
}

nav .nav-menu{
    width: 100%;
    display: grid;
    grid-auto-flow: column;
}

nav ul li{
    cursor: pointer;
    list-style-type: none;
    text-align: center;
}

nav ul li:hover{
    font-weight: bold;
}

nav .menu-icon{
    display: none;
    background: none;
    border: none;
    color: var(--primary);
}
@media screen and (max-width: 1200px) {
    nav{
        padding: 20px 50px;
    }
}


@media screen and (max-width: 768px) {
    nav{
        padding: 0;
    }

    nav ul{
        padding: 0;
        width: 230px;
    }

    nav ul li{
        margin: 10px;
    }

    nav .nav-menu{
        background: var(--base);
        position: absolute;
        top: -100%;
        transition: all 0.5s ease;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: block;
    }

    nav .nav-menu.active{
        opacity: 1;
        top: 60px;
        transition: all 0.5s ease-in;
    }

    nav .menu-icon {
        padding: 20px 30px;
        text-align: end;
        display: block;
    }
}