@import url("../../../constant/variable.css");


.b-menu ul{
    padding: 0;
    display: flex;
    justify-content: space-around;
}

.b-menu ul li{
    list-style: none;
    align-items: center;
}

.b-menu .btn-head{
    width: 70px;
    height: 30px;
    border: none;
    background: var(--primary);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.b-menu .btn-head:hover{
    color: var(--primary);
    background: var(--base);
    transition: 0.3s;
}

.b-menu .b-card{
    cursor: pointer;
    height: 60px;
    border: 1px solid var(--primary);
    padding: 20px;
    border-radius: 10px;
    transition: 0.3s;
}

.b-menu .b-card:hover{
    scale: 1.005;
    box-shadow: 4px 4px 50px 10px rgb(0 0 0 / 10%);
    transition: 0.3s;
}

.b-menu .b-card p{
    font-weight: 600;
    margin-bottom: 10px;
}