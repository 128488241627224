.app{
    padding: 50px 100px;
}

@media screen and (max-width: 1200px) {
    .app{
        padding: 50px 50px;
    }
}

@media screen and (max-width: 768px) {
    .app {
        padding: 50px 30px;
    }
}
